.node > rect {
  stroke: #000;
  stroke-width: .5px;
  fill: #fff;
  cursor: pointer;
}

.node > rect:hover{
  fill: #e6e6e6;
}

/* When filtering for branches, display nodes which don't belong to branch in lighter grey */
.node-hint > rect {
  stroke: #AAA;
  stroke-width: .5px;
  fill: #fff;
  cursor: pointer;
}

.node-hint > rect:hover{
  fill: #f6f6f6;
}

.node-hint text{
  fill: #AAA;
}

g.master > rect {
  fill: #8BC34A;
  stroke: #8BC34A;
}
g.master_branch > rect{
  fill: #D9FCDA;
  stroke: #8BC34A;
}

g.current > rect {
  fill: #FCF8E3;
}

.edgePath {
  stroke: #000;
  stroke-width: 1.5px;
  fill: none;
  cursor: pointer;
}

.node, .edgePath:focus {
    outline: none;
}

.dag {
  border: 1px solid #ddd;
  border-radius: 5px;
}

.dag-tools {
  float:right;
}

span.branch{
  cursor:pointer;
}
span.unlocked{
  cursor:pointer;
}

span.forbidden{
  cursor: not-allowed;
}

/* need this to prevent node text from interfering with the pointer.  */
text {
  pointer-events: none;
}

.dag_note {
  font-weight: bold;
  text-shadow: 0 1px 0 #fff, 0 -1px 0 #fff, 1px 0 0 #fff, -1px 0 0 #fff;
  border: 1px solid #444;
  border-radius: 5px;
  background: #fff;
  padding: 0.3em 1em;
}

.dag_note .tooltip-inner {
  max-width: 400px;
}
